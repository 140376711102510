import React, { ChangeEvent, useEffect, useState, useCallback } from 'react';
import { Select, SelectItem } from '@carbon/react';
import { EmissionSource } from '../../../types/SurveySupplierInput';

type Good = {
  cn_code: string;
};

type Props = {
  index: number;
  id: string;
  className: string;
  emissionSource: EmissionSource;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  fetchSurveyGood: (emissionName: string | undefined) => Promise<any[]>;
  setFinalEmissionSources: React.Dispatch<React.SetStateAction<EmissionSource[]>>;
  defaultValue: string;
  loadCnCodes: boolean | undefined;
};

const CNCell = ({
  index,
  id,
  className,
  emissionSource,
  fetchSurveyGood,
  onChange,
  setFinalEmissionSources,
  defaultValue,
  loadCnCodes,
}: Props) => {
  const [goods, setGoods] = useState<Good[]>([]);

  const { emission_name, cn_code } = emissionSource;

  const fetchGoods = useCallback(async () => {
    try {
      const data = await fetchSurveyGood(emission_name);
      setGoods(data);

      setFinalEmissionSources((prev: EmissionSource[]): EmissionSource[] => {
        return prev.map((emission, i) =>
          i === index ? { ...emission, cn_code: data[0].cn_code } : emission,
        );
      });
    } catch (error) {
      console.error('Failed to fetch emission data', error);
    }
  }, [index, emission_name, fetchSurveyGood, setFinalEmissionSources]);

  useEffect(() => {
    if (loadCnCodes && !cn_code) {
      fetchGoods();
    }
  }, [fetchGoods, loadCnCodes, cn_code]);

  if (goods.length < 2) {
    return (
      <span className={className} id={id}>
        {defaultValue}
      </span>
    );
  }

  return (
    <Select
      className={className}
      id={id}
      labelText=""
      onChange={onChange}
      defaultValue={defaultValue}
    >
      {goods.map((good) => (
        <SelectItem key={good.cn_code} value={good.cn_code} text={good.cn_code} />
      ))}
    </Select>
  );
};

export default CNCell;
