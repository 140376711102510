import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Form,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  NumberInput,
  Checkbox,
  InlineNotification,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep6.scss';
// import { useFormState } from 'react-final-form';
import VideoEmbed from '../../Vimeo/Vimeo';


// test

const FormStep6 = ({ formState, handleChange, prevStep, nextStep }: any) => {
  console.log('FormStep6', formState.step, handleChange);
  const { t } = useTranslation();
  const [ProductionTotalInvalid, setProductionTotalInvalid] = useState(false);
  const [ProductionScrapInvalid, setProductionScrapInvalid] = useState(false);
  const [ProductionCalcInvalid, setProductionCalcInvalid] = useState(false);
  const [hasAttemptedNextStep, setHasAttemptedNextStep] = useState(false);
  const [productionCalc, setProductionCalc] = useState<number>(0);
  const [CarbonContentChecked, setCarbonContentChecked] = useState(formState.CarbonContentChecked ? formState.CarbonContentChecked : false);
  const [ProductionCarbonContentInvalid, setProductionCarbonContentInvalid] = useState(false);
  const [videoKey, setVideoKey] = useState(0); // Add videoKey state

  useEffect(() => {
    if (formState.productionTotal) {
      setProductionTotalInvalid(false);
    } else {
      setProductionTotalInvalid(true);
    }
  }, [formState.productionTotal]);

  useEffect(() => {
    if ((formState.productionCarbonContent > 0 && formState.productionCarbonContent <= 1) || !CarbonContentChecked) {
      setProductionCarbonContentInvalid(false);
    } else {
      setProductionCarbonContentInvalid(true);
    }
  }, [formState.productionCarbonContent, CarbonContentChecked]);

  useEffect(() => {
    if (formState.productionScrap) {
      setProductionScrapInvalid(false);
    } else {
      setProductionScrapInvalid(true);
    }
  }, [formState.productionScrap]);

  useEffect(() => {
    if (formState.productionCalc <= 0) {
      setProductionCalcInvalid(true);
      console.log('Production Calc invalid');
    } else {
      setProductionCalcInvalid(false);
      console.log('Production Calc valid');
    }
  }, [formState.productionCalc]);

  useEffect(() => {
    const difference = formState.productionTotal - formState.productionScrap;
    setProductionCalc(difference);

    const calculatedProduction: FormStateAttribute = {
      name: 'productionCalc',
      value: difference,
    };
    handleChange(calculatedProduction);
  }, [formState.productionTotal, formState.productionScrap]);

  useEffect(() => {
    console.log(`CarbonContentChecked changed to: ${CarbonContentChecked}`);
    console.log(CarbonContentChecked);
    const customEventCarbonContentChecked = {
      target: {
        name: 'CarbonContentChecked',
        value: CarbonContentChecked,
      },
    };

    // Update state with new emissions values
    console.log('Updating formstate CarbonContentChecked:', customEventCarbonContentChecked);
    handleChange(customEventCarbonContentChecked as any);
  }, [CarbonContentChecked]);

  // Adjust the onChange handler for the CarbonContentChecked checkbox
  const handleCarbonContentCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    console.log(`Checkbox clicked, new checked value: ${checked}`); // Diagnostic log
    setCarbonContentChecked(checked);
  };


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setHasAttemptedNextStep(true);
    console.log('next step attempted');
    if ((!ProductionTotalInvalid && !ProductionScrapInvalid && !ProductionCalcInvalid && (!ProductionCarbonContentInvalid || !CarbonContentChecked))) {

    }
    {
      console.log('next step validated');

      // Calculate negative emissions for mass balance

      if (formState.route['calculation_method'] === 'mass balance') {
        console.log(' mass balance');
        if (CarbonContentChecked) {

          // Calculation:
          const negativeEmissions = formState.productionCalc * formState.productionCarbonContent * 3.664;
          // Log the negative emissions value
          console.log('Negative Emissions:', negativeEmissions);

          // Update calculatedEmissionsDirectMB
          const calculatedEmissionsDirectMB = formState.calculatedEmissionsDirect - negativeEmissions;
          if (calculatedEmissionsDirectMB > 0) {


            nextStep(event);


            const customEventcalculatedEmissionsDirectMB = {
              target: {
                name: 'calculatedEmissionsDirectMB',
                value: calculatedEmissionsDirectMB,
              },

            };
            console.log('Writing calculatedEmissionsDirectMB to formstate:', customEventcalculatedEmissionsDirectMB);
            handleChange(customEventcalculatedEmissionsDirectMB as any);
          } else {
            setProductionCarbonContentInvalid(true);
            // Display error message
            console.log('Negative overall direct emissions are not allowed', calculatedEmissionsDirectMB);
          }
        } else {
          console.log('not mass balance');
          nextStep(event);
          const customEventcalculatedEmissionsDirectMB = {
            target: {
              name: 'calculatedEmissionsDirectMB',
              value: formState.calculatedEmissionsDirect,
            },
          };
          console.log('Set calculatedEmissionsDirectMB to calculatedEmissionsDirect', customEventcalculatedEmissionsDirectMB);
          handleChange(customEventcalculatedEmissionsDirectMB as any);
        }
      } else {
        console.log('default route');
        nextStep(event);
      }

      // Save negative emissions to directEmissions

      // TODO

      // Calculate new emissions values
      const newCalculatedEmissionsDirect = Number((
        (formState.calculatedEmissionsDirectMB && formState.calculatedEmissionsDirectMB !== 0 ?
          formState.calculatedEmissionsDirectMB :
          formState.calculatedEmissionsDirect) / productionCalc
      ).toFixed(2));
      const newCalculatedEmissionsIndirect = Number((formState.calculatedEmissionsIndirect / productionCalc).toFixed(2));
      const newCalculatedEmissionsTotal = (newCalculatedEmissionsDirect + newCalculatedEmissionsIndirect).toFixed(2);
      console.log('Direct value selected for calc:', newCalculatedEmissionsDirect);
      console.log('Dividing by ', productionCalc);

      // Construct a custom event object for handleChange
      // Update the calculatedEmissions with new calculated values
      const customEventDirect = {
        target: {
          name: 'calculatedEmissionsDirectPerTonne',
          value: newCalculatedEmissionsDirect,
        },
      };

      const customEventIndirect = {
        target: {
          name: 'calculatedEmissionsIndirectPerTonne',
          value: newCalculatedEmissionsIndirect,
        },
      };

      const customEventTotal = {
        target: {
          name: 'calculatedEmissionsTotalPerTonne',
          value: newCalculatedEmissionsTotal,
        },
      };

      // Update state with new emissions values
      console.log('Updating Direct Per Tonne:', customEventDirect);
      handleChange(customEventDirect as any);
      console.log('Updating Direct Per Tonne:', customEventIndirect);
      handleChange(customEventIndirect as any);
      console.log('Updating Total Per Tonne:', customEventTotal);
      handleChange(customEventTotal as any);

      // calculation method is always calculated
      /*else if (formState.calculationMethod === 'default') {
       console.log('calculating total emissions using default method');
       const newCalculatedEmissionsTotal = formState.calculatedEmissionsDirectPerTonne + formState.calculatedEmissionsIndirectPerTonne;
       const customEventTotal = {
         target: {
           name: 'calculatedEmissionsTotalPerTonne',
           value: newCalculatedEmissionsTotal,
         },
       };
       handleChange(customEventTotal as any);

       const customEventcalculatedEmissionsDirectMB = {
         target: {
           name: 'calculatedEmissionsDirectMB',
           value: 0,
         },
       };
       console.log('Set calculatedEmissionsDirectMB to 0', customEventcalculatedEmissionsDirectMB);
       handleChange(customEventcalculatedEmissionsDirectMB as any);
       nextStep(event);
     }*/
    }
  };

  return (
    <Form onSubmit={handleSubmit} id="step6" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>
                {t('survey.step6.name')} {formState.start} - {formState.end}
              </h4>
              <p><InlineNotification title= {t('survey.step6.notificationTitle')} subtitle={t('survey.step6.notificationSubtitle')} kind="info" hideCloseButton="true"/></p>
              {formState.calculationMethod === 'default' && ('You chose to use default values. Activity data does not have to be disclosed.')}
            </Column>
            {formState.calculationMethod !== 'default' && (
              <Column lg={16} md={8} sm={4}>
                <Table size="lg" useZebraStyles={false} aria-label="sample table">
                  <TableHead>
                    <TableRow>
                      <TableHeader>{t('survey.step6.production')}</TableHeader>
                      <TableHeader>{t('survey.step6.productionVolume')}</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {t('survey.step6.productionTotal.labelText')}
                      </TableCell>
                      <TableCell>
                        <NumberInput
                          step={0.01}
                          name="productionTotal"
                          id="productionTotal"
                          label={t('survey.step6.productionTotal.labelText')}
                          helperText={t('survey.step6.productionTotal.helperText')}
                          invalidText={t('survey.step6.productionTotal.invalidText')}
                          value={formState.productionTotal ? formState.productionTotal : ''}
                          required
                          onChange={handleChange}
                          invalid={ProductionTotalInvalid && hasAttemptedNextStep}
                          allowEmpty={true}
                          hideSteppers={true}
                        ></NumberInput>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t('survey.step6.productionScrap.labelText')}</b>
                      </TableCell>
                      <TableCell>
                        <NumberInput
                          step={0.01}
                          name="productionScrap"
                          id="productionScrap"
                          label={t('survey.step6.productionScrap.labelText')}
                          helperText={t('survey.step6.productionScrap.helperText')}
                          invalid={ProductionScrapInvalid && hasAttemptedNextStep}
                          invalidText={t('survey.step6.productionScrap.invalidText')}
                          value={formState.productionScrap ? formState.productionScrap : ''}
                          required
                          onChange={handleChange}
                          allowEmpty={true}
                          hideSteppers={true}
                        ></NumberInput>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <b>{t('survey.step6.productionCalc.labelText')}</b>
                      </TableCell>
                      <TableCell>
                        <NumberInput
                          step={0.01}
                          name="productionCalc"
                          id="productionCalc"
                          label={t('survey.step6.productionCalc.labelText')}
                          helperText={t('survey.step6.productionCalc.helperText')}
                          invalid={ProductionCalcInvalid}
                          invalidText={t('survey.step6.productionCalc.invalidText')}
                          value={formState.productionCalc ? formState.productionCalc : ''}
                          allowEmpty={true}
                          readOnly={!ProductionCalcInvalid}
                          hideSteppers={true}
                        ></NumberInput>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {formState.route['calculation_method'] === 'mass balance' && formState.calculationMethod === 'calculated' && (
                  <>
                    <br />
                    <br />
                    <h6>
                      Mass balance
                    </h6>
                    <br />

                    <Checkbox
                      labelText={`Inform carbon content of output to further reduce emissions`}
                      name="CarbonContentChecked"
                      id="CarbonContentChecked"
                      defaultChecked={formState.CarbonContentChecked}

                      onChange={handleCarbonContentCheckedChange}
                    />
                    <br />
                    {CarbonContentChecked && (
                      <NumberInput
                        step={0.01}
                        name="productionCarbonContent"
                        id="productionCarbonContent"
                        label={t('survey.step6.productionCarbonContent.labelText')}
                        helperText={t('survey.step6.productionCarbonContent.helperText')}
                        invalid={ProductionCarbonContentInvalid && hasAttemptedNextStep}
                        invalidText={t('survey.step6.productionCarbonContent.invalidText')}
                        value={formState.productionCarbonContent ? formState.productionCarbonContent : ''}
                        allowEmpty={true}
                        hideSteppers={true}
                        onChange={handleChange}
                      ></NumberInput>
                    )}
                  </>
                )}
              </Column>
            )}

          </Grid>
        </div>

        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq">{t('survey.step6.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step6.faq.accordion1.title')}>
                  <p>{t('survey.step6.faq.accordion1.text')}</p>
                </AccordionItem>
                <AccordionItem title={t('survey.step6.faq.accordion2.title')}>
                  <p>
                    <div>
                      <VideoEmbed
                        src="https://video.ibm.com/embed/recorded/133967497"
                        title="CBAM Academy - Activity Levels"
                        width="100%"
                        height="auto"
                        key={videoKey.toString()} // Convert videoKey to string
                      />
                    </div>
                  </p>
                </AccordionItem>
                <AccordionItem title={t('survey.step6.faq.accordion3.title')}>
                  <p>{t('survey.step6.faq.accordion3.text')}</p>
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>

      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" tabIndex={0} onClick={prevStep}>
                {t('common.buttons.back')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};
export default FormStep6;
