import { Theme } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Layout from './layouts/Layout';

// design collab
import { MenuPoint } from './types/MenuPoint';

import './App.scss';
import NoMatchPage from './pages/NoMatchPage/NoMatchPage';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import CbamAcademy from './pages/CbamAcademy/CbamAcademy';
import SBOM from './pages/Legal/SBOM';
import { SurveyViewData } from './types/SurveyViewData';

// OIDC
import { OidcConfiguration } from '@axa-fr/oidc-client';
import { LOCAL_STORAGE_OIDC_CONFIG_NAME, LOCAL_STORAGE_RELOAD_FLAG } from './constants/constants';
import { OidcProvider } from '@axa-fr/react-oidc';
import SessionExpiredComponent from './oidc/SessionExpiredComponent';
import PrivacyNotice from './pages/Legal/PrivacyNotice';

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const surveyId = queryParams.get('surveyId');
  const testCN = queryParams.get('testCN');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const menuPoints: MenuPoint[] = [];
  const [surveyDataLoading, setSurveyDataLoading] = useState(false);

  const [currentTheme, setCurrentTheme] = useState('g10');
  const [activePage, setActivePage] = useState(0);
  const [reload, setReload] = useState(false);
  const [survey, setSurvey] = useState<SurveyViewData>();

  function fetchOIDCConfig(surveyId: string) {
    console.log("Fetching OIDC config for survey ID:", surveyId);
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
    setSurveyDataLoading(true);
    fetch(`${backendUrl}/api/surveys/oidc/${surveyId}`, {
      method: 'GET',
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((config) => {

        const updatedConfiguration = {
          client_id: config.client_id,
          redirect_uri: window.location.origin + '/authentication/callback',
          silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
          scope: config.scope,
          authority: config.authority,
          service_worker_relative_url: config.service_worker_relative_url,
          service_worker_only: config.service_worker_only || false,
          demonstrating_proof_of_possession: config.demonstrating_proof_of_possession,
          token_renew_mode: 'id_token_invalid',
        };

        setConfiguration(updatedConfiguration);
        const oidcConfig = localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME);
        localStorage.setItem(LOCAL_STORAGE_OIDC_CONFIG_NAME, JSON.stringify(updatedConfiguration));
        setSurveyDataLoading(false);

        if (oidcConfig !== JSON.stringify(updatedConfiguration)) {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error fetching OIDC config:", error);
      });
  }


  // get survey by id
  useEffect(() => {
    // Define headers for fetching survey data
    let myHeaders = new Headers();
    myHeaders.append('accept', 'application/json');
  
    // Define requestOptions for fetch call
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
  
    if (surveyId) {
      setSurveyDataLoading(true);
      // Fetch survey by ID if surveyId is present
      console.log("BackendURL: ", backendUrl)
      fetch(`${backendUrl}/api/surveys/${surveyId}`, requestOptions)
        .then((response) => response.json())
        .then((data: SurveyViewData) => {
          setSurvey(data);
          console.log('database - surveydata', data);
          if (!data.oidc_required) {
            setSurveyDataLoading(false);
          }    
          })
        .catch((error) => console.log('error', error));
        
    } else if (testCN) {
      setSurveyDataLoading(true);
      // Use dummy data and the testCN if surveyId is not present but testCN is
      fetch(`${backendUrl}/api/surveys/agc/${testCN}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const testAGC = data.agc;
        console.log('database - agc', data);
        const dummyData : SurveyViewData = {
          id: '4f2869e6-9e96-4e3d-9a63-5b52d45c4103',
          cn_number: testCN !== null ? testCN : '',
          status: 'Sample Status',
          supplier_id: 'dummy_supplier_id',
          supplier_name: 'Sample Supplier Name',
          item_nrs: 'Sample Importer Item Nrs',
          supplier_item_nrs: 'Sample Supplier Item Nrs',
          order_nrs: 'Sample Order Nrs',
          aggregated_goods_category : testAGC,
          oidc_required: false,
          base_color: '#F2F2F2',
          logo: '',

        };
    
        setSurvey(dummyData);
        console.log('using dummy data - surveydata', dummyData);
        setSurveyDataLoading(false);
      })
      .catch((error) => console.log('error', error));
    } else {
      // Handle the case where both surveyId and testCN are missing
      setSurveyDataLoading(false);
      console.error('Error: Both surveyId and testCN are missing. Unable to fetch or generate survey data.');
    }
  }, [surveyId, testCN, reload]); // Add both surveyId and testCN as dependencies

  useEffect(() => {
    console.log(survey?.oidc_required, "survey oidc required");
    if (survey?.oidc_required) {
      console.log("Fetching OIDC config for survey", surveyId);
      fetchOIDCConfig(survey?.id);
    } else {
      console.log("OIDC config not required for survey", surveyId);
      
    }
  } , [survey]);


    // Initial configuration with default values
  const [configuration, setConfiguration] = useState<OidcConfiguration>(localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_OIDC_CONFIG_NAME) as string) : {
    client_id: '',
    redirect_uri: window.location.origin + '/authentication/callback',
    silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
    scope: '',
    authority: '',
    service_worker_relative_url: '',
    service_worker_only: false,
    demonstrating_proof_of_possession: false,
    token_renew_mode: 'id_token_invalid',
  });

  return (
     <BrowserRouter>
       <OidcProvider configuration={configuration} sessionLostComponent={SessionExpiredComponent}>
        <Theme className="themeWrapper" theme={currentTheme}>
          <Layout
            menuPoints={menuPoints}
            setCurrentTheme={setCurrentTheme}
            appPrefix={survey?.supplier_name || ''}
            activePage={activePage}
            setActivePage={setActivePage}
            privacyUrl={survey?.privacy_url}
            baseColor={survey?.base_color || '#F2F2F2'}
            logo={survey?.logo || ''}

          >
            <Routes>
              <Route path="/">
                <Route element={<WelcomePage survey={survey} surveyDataLoading={surveyDataLoading}/>} path="/" />
                <Route element={<SurveyPage setReload={setReload}/>} path="submission" />
                <Route element={<NoMatchPage />} path="error" />
                <Route element={<CbamAcademy survey={survey} surveyDataLoading={surveyDataLoading}/>} path="academy" />
                <Route element={<SBOM />} path="SBOM" />
                <Route element={<PrivacyNotice />}path="privacy" />
              </Route>
            </Routes>
          </Layout>
        </Theme>
      </OidcProvider>
   </BrowserRouter>
  );
}

export default App;