import {
  Accordion,
  AccordionItem,
  Button,
  ButtonSet,
  Column,
  Dropdown,
  ComboBox,
  Form,
  Grid,
  TextInput,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormStateAttribute } from '../FormMultisteps';
import '../FormMultisteps.scss';
import './FormStep1.scss';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {validateEmail} from "../../../untils/validateEmail";
import useCountry from "../../../hooks/useCountry";

const FormStep1 = ({ formState, handleDiscard, handleChange, nextStep }: any) => {
  const { t } = useTranslation();
  const countryList = useCountry();
  const [hasAttemptedNextStep, setHasAttemptedNextStep] = useState(false);
  const [TIEmailInvalid, setTIEmailInvalid] = useState<Boolean>(false);
  const [TIPhoneInvalid, setTIPhoneInvalid] = useState<Boolean>(false);
  const [TIStreetNumberInvalid, setTIStreetNumberInvalid] = useState<Boolean>(false);
  const [CountryListInvalid, setCountryListInvalid] = useState<Boolean>(!formState.country);

  const handleEmailInput = (event: any) => {
    const isInvalidInput = !validateEmail(event.target.value);
    setTIEmailInvalid(isInvalidInput);
    handleChange(event);
  };

const handlePhoneInput = (event: any) => {
  const phoneNumber = event.target.value;
  const isInvalidInput = !isValidPhoneNumber(phoneNumber);
  setTIPhoneInvalid(isInvalidInput);
  handleChange(event);
};

  const handleStreetNumber = (event: any) => {
    const re = /^\s*\w+(\s*[-\/\s]\s*\w+)*\s*$/;
    const isInvalidInput = !re.test(event.target.value);
    setTIStreetNumberInvalid(isInvalidInput);
    handleChange(event);
  };

  const handleCountry = (event: any) => {
    const chosenCountry: FormStateAttribute = {
      name: 'country',
      value: event.selectedItem, // Save the entire selected country object to retrieve it again
    };
    setCountryListInvalid(false);
    handleChange(chosenCountry);
  };
  const handleNextStep = (event: any) => {
    setHasAttemptedNextStep(true);

    if (CountryListInvalid || TIPhoneInvalid || TIEmailInvalid || TIStreetNumberInvalid) {
      event.preventDefault();
      // handle the case where country is not selected
    } else {
      nextStep(event); // proceed to the next step
    }
  };
  
  return (
    <Form onSubmit={handleNextStep} id="step1" className="form-step">
      <div className="grid-container-element">
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h4>{t('survey.step1.name')}</h4>
              {t('survey.step1.description')}
            </Column>

            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="installationName"
                id="installationName"
                labelText={t('survey.step1.installationName.labelText')}
                helperText={t('survey.step1.installationName.helperText')}
                invalidText={t('survey.step1.installationName.invalidText')}
                placeholder={t('survey.step1.installationName.placeholder')}
                required
                maxLength={128}
                value={formState.installationName}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="economicActivity"
                id="economicActivity"
                labelText={t('survey.step1.economicActivity.labelText')}
                helperText={t('survey.step1.economicActivity.helperText')}
                invalidText={t('survey.step1.economicActivity.invalidText')}
                placeholder={t('survey.step1.economicActivity.placeholder')}
                required
                maxLength={128}
                value={formState.economicActivity}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="street"
                id="street"
                labelText={t('survey.step1.street.labelText')}
                helperText={t('survey.step1.street.helperText')}
                invalidText={t('survey.step1.street.invalidText')}
                placeholder={t('survey.step1.street.placeholder')}
                required
                value={formState.street}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="streetNumber"
                id="streetNumber"
                labelText={t('survey.step1.streetNumber.labelText')}
                helperText={t('survey.step1.streetNumber.helperText')}
                invalidText={t('survey.step1.streetNumber.invalidText')}
                placeholder={t('survey.step1.streetNumber.placeholder')}
                required
                value={formState.streetNumber}
                invalid={TIStreetNumberInvalid}
                onChange={handleStreetNumber}
              />
            </Column>

            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="POBox"
                id="POBox"
                labelText={t('survey.step1.POBox.labelText')}
                helperText={t('survey.step1.POBox.helperText')}
                invalidText={t('survey.step1.POBox.invalidText')}
                placeholder={t('survey.step1.POBox.placeholder')}
                value={formState.POBox}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="additionalLine"
                id="additionalLine"
                labelText={t('survey.step1.additionalLine.labelText')}
                helperText={t('survey.step1.additionalLine.helperText')}
                invalidText={t('survey.step1.additionalLine.invalidText')}
                placeholder={t('survey.step1.additionalLine.placeholder')}
                value={formState.additionalLine}
                onChange={handleChange}
                maxLength={128}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="postCode"
                id="postCode"
                labelText={t('survey.step1.postCode.labelText')}
                helperText={t('survey.step1.postCode.helperText')}
                invalidText={t('survey.step1.postCode.invalidText')}
                placeholder={t('survey.step1.postCode.placeholder')}
                required
                value={formState.postCode}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="city"
                id="city"
                labelText={t('survey.step1.city.labelText')}
                helperText={t('survey.step1.city.helperText')}
                invalidText={t('survey.step1.city.invalidText')}
                placeholder={t('survey.step1.city.placeholder')}
                required
                value={formState.city}
                onChange={handleChange}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <ComboBox
                name="country"
                id="country"
                titleText={t('survey.step1.country.labelText')}
                helperText={t('survey.step1.country.helperText')}
                invalidText={t('survey.step1.country.invalidText')}
                placeholder={t('survey.step1.country.placeholder')}
                label=""
                // initialSelectedItem={countryList[0]}
                // label="Option 1"
                required
                items={countryList}
                itemToString={(item: any) => (item ? item.country_name : '')}
                //selectedItem={formState.country && formState.country !== 0 ? formState.country :null}
                selectedItem={formState.country}
                invalid={hasAttemptedNextStep && CountryListInvalid}
                onChange={handleCountry}
                typeahead
              />
            </Column>

            {/* Empty Column to separate from previous fields */}
            <Column lg={8} md={4} sm={4}></Column>

            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="representative"
                id="representative"
                labelText={t('survey.step1.representative.labelText')}
                helperText={t('survey.step1.representative.helperText')}
                invalidText={t('survey.step1.representative.invalidText')}
                placeholder={t('survey.step1.representative.placeholder')}
                required
                value={formState.representative}
                onChange={handleChange}
                maxLength={128}
              />
            </Column>
            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="telephone"
                id="telephone"
                labelText={t('survey.step1.telephone.labelText')}
                helperText={t('survey.step1.telephone.helperText')}
                invalidText={t('survey.step1.telephone.invalidText')}
                placeholder={t('survey.step1.telephone.placeholder')}
                invalid={TIPhoneInvalid}
                required
                value={formState.telephone}
                onChange={handlePhoneInput}
                
              />
            </Column>



            <Column lg={8} md={4} sm={4}>
              <TextInput
                name="email"
                id="email"
                labelText={t('survey.step1.email.labelText')}
                helperText={t('survey.step1.email.helperText')}
                invalidText={t('survey.step1.email.invalidText')}
                placeholder={t('survey.step1.email.placeholder')}
                invalid={TIEmailInvalid}
                required
                maxLength={128}
                value={formState.email}
                onChange={handleEmailInput}
              />
            </Column>
          </Grid>
        </div>
        <div className="grid-child-element">
          <Grid>
            <Column lg={16} md={8} sm={4}>
              <h5 className="faq-title">{t('survey.step1.faq.title')}</h5>
              <Accordion>
                <AccordionItem title={t('survey.step1.faq.accordion1.title')}>
                  <p>{t('survey.step1.faq.accordion1.text')}</p>
                </AccordionItem>
                <AccordionItem title={t('survey.step1.faq.accordion2.title')}>
                  <p>{t('survey.step1.faq.accordion2.text')}</p>
                </AccordionItem>
                <AccordionItem title={t('survey.step1.faq.accordion3.title')}>
                  <p>{t('survey.step1.faq.accordion3.text')}</p>
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </div>
      <Grid>
        <Column lg={16} md={8} sm={4}>
          <div className="step-btn-set-container">
            <ButtonSet className="step-btn-set">
              <Button kind="secondary" onClick={handleDiscard}>
                {t('common.buttons.discard')}
              </Button>
              <Button kind="primary" tabIndex={0} type="submit">
                {t('common.buttons.next')}
              </Button>
            </ButtonSet>
          </div>
        </Column>
      </Grid>
    </Form>
  );
};

export default FormStep1;
