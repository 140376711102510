import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Grid,
  Column,
  Modal,
  Search,
  Loading,
 } from '@carbon/react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import './CbamAcademy.scss';
import greenFactory from '../../assets/img/factory.png';
import improvedFactory from '../../assets/img/improved_factory.png';
import React, { useState, useRef, useEffect } from 'react';
import { InfoSection, InfoCard, VideoCard } from '../../components/Info/Info';
import SearchFunction from '../../components/Search/Search';
import faqsData from '../../data/Faqs';
import VideoEmbed from '../../components/Vimeo/Vimeo'; 
import { SurveyViewData } from '../../types/SurveyViewData';
import { SkeletonText } from '@carbon/react';
import { LOCAL_STORAGE_RELOAD_FLAG } from '../../constants/constants';
import ParameterTable from '../../components/ParameterTable/ParameterTable';
import ParameterTableSkeleton from '../../components/ParameterTable/ParameterTableSkeleton';


const CbamAcademy = ( { survey, surveyDataLoading }: any ) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const surveyId = localStorage.getItem('surveyId');
  console.log(surveyId);
  

  useEffect(() => {
  });

  if (surveyId != null && survey == null && surveyDataLoading == false) {
    if (localStorage.getItem(LOCAL_STORAGE_RELOAD_FLAG) === 'true'){
      window.location.reload();
      localStorage.removeItem(LOCAL_STORAGE_RELOAD_FLAG);
    }
  }

 
  return (
<Grid className="landing-page" fullWidth>
{survey && !surveyDataLoading ? console.log('all good') : console.log ('loading') }
<Column lg={16} md={8} sm={4} className="landing-page__banner">
  <h1 className="landing-page__heading">CBAM Academy</h1>
  {survey ? 
              t('welcome.header', {
                importerName: survey['importer_name'],
              }) :
              <SkeletonText heading paragraph />
              }
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r2">
  <Tabs defaultSelectedIndex={0}>
    <TabList className="tabs-group">
      <Tab>Introduction to CBAM</Tab>
      <Tab>Using CORA</Tab>
      <Tab>FAQ & Support</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <Grid className="tabs-group-content">
          <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">What is CBAM?</h2>
            <p className="landing-page__p">
            With the Carbon Border Adjustment Mechanism (CBAM), the EU has introduced a set of rules from October 2023 that will force importers of various key raw materials and intermediate products to take action. From the last quarter of 2023, this border adjustment mechanism provides for a reporting obligation for companies that import goods with a high carbon footprint from non-EU countries.
            </p>
            <Button onClick={() => setOpen(true)}>Watch a short video about CBAM</Button>
            <Modal open={open} onRequestClose={() => setOpen(false)} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
            <p style={{
        marginBottom: '1rem'
      }}>
 <iframe className="modalVideo" src="https://video.ibm.com/embed/recorded/133975307" loading="lazy" title="Synthesia video player - CORA Context Help - Welcome" allow="encrypted-media; fullscreen;"></iframe>
        </p>

  </Modal>
          </Column>
          <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
  <img className="landing-page__illo"
    src={greenFactory}
    alt="Carbon illustration"
    width={367}
    height={350}
  />
</Column>
<Column lg={16} md={8} sm={4} className="shortVideoBox">
<h3>{t('surveyInfo.walkthroughTitle')}</h3> 
<p>{t('surveyInfo.walkthroughSubtitle')}</p>
</Column>
<Column lg={6} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video11Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/134063397"
                  title="CBAM Academy - Full Survey Walkthrough English" 
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video11Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={6} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video12Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/134112264"
                  title="CBAM Academy - Full Survey Walkthrough Chinese" 
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video12Description')}</p>
            </div>
          </div>
        </Column>
<Column lg={16} md={8} sm={4} className="shortVideoBox">
<h3>{t('surveyInfo.shortTitle')}</h3> 
<p>{t('surveyInfo.shortSubtitle')}.</p>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video1Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133972395"
                  title="CBAM Academy - Reporting Periods for Operators"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video1Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video2Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967506"
                  title="CBAM Academy - Calculating Direct Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video2Description')}</p>
            </div>
          </div>
        </Column>
       
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video3Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967504"
                  title="CBAM Academy - Calculating Indirect Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video3Description')}</p>
            </div>
          </div>
        </Column>
       
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video4Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967502"
                  title="CBAM Academy - Calculating Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video4Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video4Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                  src="https://video.ibm.com/embed/recorded/133967501"
                  title="CBAM Academy - Aggregated Goods Categories"
                  width="100%"
                  height="auto"
                 />
    <div className="video-description">
      <p>{t('surveyInfo.video4Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video5Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                          src="https://video.ibm.com/embed/recorded/133967505"
                          title="CBAM Academy - Carbon Price"
                          width="100%"
                          height="auto"
                       
                      />
    <div className="video-description">
      <p>{t('surveyInfo.video5Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video6Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                          src="https://video.ibm.com/embed/recorded/133967497"
                          title="CBAM Academy - Activity Levels"
                          width="100%"
                          height="auto"
                                            />
    <div className="video-description">
      <p>{t('surveyInfo.video6Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video7Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                          src="https://video.ibm.com/embed/recorded/133967498"
                          title="CBAM Academy - Qualitative Reporting Parameters"
                          height="auto"
                          
                      />
    <div className="video-description">
      <p>{t('surveyInfo.video7Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video9Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                src="https://video.ibm.com/embed/recorded/133967500"
                title="CBAM Academy - Subsurveys"
                width="100%"
                height="auto"
              />
    <div className="video-description">
      <p>{t('surveyInfo.video9Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video8Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                src="https://video.ibm.com/embed/recorded/133967503"
                title="CBAM Academy - Survey Finished - Next Steps"
                width="100%"
                height="auto"
                />
    <div className="video-description">
      <p>{t('surveyInfo.video8Description')}</p>
    </div>
  </div>
</Column>

        </Grid>
      </TabPanel>
      <TabPanel>
      <Grid className="tabs-group-content">
          <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">What is CORA?</h2>
            <p className="landing-page__p">
            CORA, also known as Carbon Optimizer & Reporting Assistent was designed to help you answer your suppliers' CBAM survey as accurately as possible while saving you time and starting in 2026 money. This tool is absolutely free for you and we recommend to use the CBAM Academy to tackle this regulation.
            </p>
            <Button onClick={() => setOpen(true)}>Watch the CORA Survey Walkthrough</Button>
            <Modal open={open} onRequestClose={() => setOpen(false)} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
        <p style={{ marginBottom: '1rem' }}>
          <iframe  className="modalVideo" loading="lazy"></iframe>
        </p>
      </Modal>
          </Column>
          <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
  <img className="landing-page__illo"
    src={improvedFactory}
    alt="Carbon illustration"
    width={450}
    height={405}
  />
</Column>
<Column lg={12} md={12} sm={12} className="videoColumn">
  <h4>{t('surveyInfo.video10Title')}</h4>  
  <div className="video-container">
  <VideoEmbed
                src="https://video.ibm.com/combined-embed/playlist/682165"
                title="CBAM Academy - CORA Walkthrough"
                width="100%"
                height="auto"
                />
    <div className="video-description">
      <p>{t('surveyInfo.video10Description')}</p>
    </div>
  </div>
</Column>

        </Grid>
      </TabPanel>
      <TabPanel>
        <Grid className="tabs-group-content">
          <Column lg={16} md={8} sm={4} className="landing-page__tab-content">
          <InfoSection heading="Frequently Asked Questions" className="landing-page__r3">
        </InfoSection>       
          </Column>
          <Column lg={16} md={8} sm={4}>
          <SearchFunction details={faqsData}/>
        </Column>
        </Grid>
      </TabPanel>
    </TabPanels>
  </Tabs>
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r3">
<InfoSection heading="The Principles" className="landing-page__r3">
  <InfoCard
    heading="CORA is Open"
    body="Once you've filled out your survey, you will send the results directly to your supplier while you have access to your results. Export to the official EU Communication template is coming soon."
    
  />
  <InfoCard
    heading="CORA is Modular"
    body="CBAM can be overwhelming. We will only ask you what your supplier really needs to generate a compliant report and nothing more. Complexity will rise in sync with your CBAM skills thanks to CORA."
   
  />
  <InfoCard
    heading="CORA is Safe"
    body="At any step of the way, you'll have total control over your data. While Excel-based solutions often transmit your total production, CORA will use the values only for the necessary calculations."
  
  />
</InfoSection>
</Column>


  </Grid>
  );
};

export default CbamAcademy;
