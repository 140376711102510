import { Grid, Column } from '@carbon/react';

// Take in a phrase and separate the third word in an array
function createArrayFromPhrase(phrase : any) {
    const splitPhrase = phrase.split(' ');
    const thirdWord = splitPhrase.pop();
    return [splitPhrase.join(' '), thirdWord];
  }

const InfoSection = (props : any) => (
  <Grid className={`${props.className} info-section`}>
    <Column md={8} lg={4} xlg={3}>
      <h3 className="info-section__heading">{props.heading}</h3>
    </Column>
    {props.children}
  </Grid>
);

const InfoCard = (props : any) => {
    const splitHeading = createArrayFromPhrase(props.heading);
  
    return (
      <Column sm={4} md={8} lg={4} className="info-card">
        <h4 className="info-card__heading">
          {`${splitHeading[0]} `}
          <strong>{splitHeading[1]}</strong>
        </h4>
        <p className="info-card__body">{props.body}</p>

      </Column>
    );
  };

  const VideoCard = (props : any) => {
   
    return (
      <Column sm={4} md={8} lg={4} className="video-card">
         <video className="info-card__body">{[props.source, props.type]}</video>
      </Column>
    );
  };
  
  export { InfoSection, InfoCard, VideoCard };