import React, { useState } from 'react';
import Scroll from './Scroll';
import SearchList from './SearchList';
import {
  Button,
  Search,
  Grid,
  Column,
 } from '@carbon/react';

function SearchFunction({ details }) {

  const [searchField, setSearchField] = useState("");

  const filteredPersons = details.filter(
    person => {
      return (
        person
        .question
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        person
        .answer
        .toLowerCase()
        .includes(searchField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchField(e.target.value);
  };

  function searchList() {
    return (
      <Scroll>
        <SearchList filteredPersons={filteredPersons} />
      </Scroll>
    );
  }

  return (
      <Column sm={4} md={8} lg={16} className="searchInput">
            <Search size="lg" placeholder="Find your topics" labelText="Search" closeButtonLabelText="Clear search input" id="search-1" onChange = {handleChange} />  

      <p>{searchList()}</p>

    </Column>


  );
}

export default SearchFunction;