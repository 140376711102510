import {useEffect, useState} from "react";


export interface Country {
    location_id: number;
    country_code: string;
    country_name: string
}

function useCountry(defaultValue: Country[] = []) {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [countryList, setCountryList] = useState<Country[]>(defaultValue);

    useEffect(() => {
        const fetchCountryList = async () => {
            try {
                const response = await fetch(`${backendUrl}/api/surveys/country_list`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCountryList(data.country_list);
            } catch (error) {
                console.warn('Error fetching country list:', error);
            }
        };

        fetchCountryList();
    }, [backendUrl]);

    return countryList;
}

export default useCountry;
