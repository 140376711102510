import {
  Grid,
  Column,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import '../CbamAcademy/CbamAcademy.scss';
import React, { useState, useRef, useEffect } from 'react';

const SBOM = () => {
  const { t } = useTranslation();
  const [packages, setPackages] = useState<Package[]>([]);
  const currentTimestamp = new Date().toLocaleString();

  interface Package {
    spdxID: string;
    name: string;
    version: string;
    downloadLocation: string;
    licenseConcluded?: string;
  }

  useEffect(() => {
    fetch('api/sbom')
      .then((response) => response.json())
      .then((data) => {
        // Adjust this line to match the structure of your actual JSON data
        setPackages(data.items);
      })
      .catch((error) => console.error('Error fetching SPDX data:', error));
  }, []);

  return (
    <Grid className="landing-page" fullWidth>
      <Column lg={16} md={8} sm={4} className="landing-page__banner">
        <h1 className="landing-page__heading">CORA Software Bill of Materials</h1>
        <p>Creation date and time: {currentTimestamp}</p>
        <p>Author: Ventum Consulting GmbH & Co. KG, Creator: Generated by Github.com</p>
        <p>Generated by Github.com</p>
      </Column>

      <Column lg={16} md={8} sm={4} className="landing-page__r2">
        <table style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
          <thead>
          <tr>
            <th style={{ width: '15%', padding: '8px' }}>SPDX ID</th>
            <th style={{ width: '25%', padding: '8px' }}>Name</th>
            <th style={{ width: '15%', padding: '8px' }}>Version</th>
            <th style={{ width: '30%', padding: '8px' }}>Download Location</th>
            <th style={{ width: '15%', padding: '8px' }}>License Concluded</th>
          </tr>
          </thead>
          <tbody>
          {packages?.map((pkg) => (
            <tr key={pkg.spdxID} style={{ borderBottom: '1px solid #ccc' }}>
              <td style={{ padding: '8px' }}>{pkg.spdxID}</td>
              <td style={{
                padding: '8px',
                textAlign: 'center',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}>{pkg.name}</td>
              <td style={{ padding: '8px', textAlign: 'center' }}>{pkg.version}</td>
              <td style={{
                padding: '8px',
                textAlign: 'center',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}>{pkg.downloadLocation}</td>
              <td style={{ padding: '8px', textAlign: 'center', whiteSpace: 'normal' }}>{pkg.licenseConcluded}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </Column>
    </Grid>
  );
};
export default SBOM;
  