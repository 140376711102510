import React from 'react';
import {
  Button,
  Search,
  Grid,
  Column,
  Tag,
 } from '@carbon/react';

function Card({person}) {
  return(
    <Column sm={4} md={8} lg={16} className="faqEntry">
    <h4 className="faqQuestion">
      {person.question}
    </h4>
    <p className="faqText">{person.answer}</p>
    <Tag className="some-class" type="teal">
        {person.category}
      </Tag>

  </Column>

  );
}

export default Card;